<template>
    <div class="account">
        <h1>My Account</h1>
    </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'Account',
  components: {
  }
}
</script>